.answer-quill-editor .ql-container,
.description-quill-editor .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.answer-quill-editor .ql-snow.ql-toolbar,
.description-quill-editor .ql-snow.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.answer-quill-editor .ql-editor {
  min-height: 5em;
}

.description-quill-editor .ql-editor {
  min-height: 10em;
}

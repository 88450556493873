.review-slider {
  height: 100%;
}
.review-slider .slick-list {
  height: inherit;
}
.review-slider .slick-next {
  right: -15px;
}
.review-slider .slick-prev {
  left: -15px;
}
.review-slider .slick-next,
.review-slider .slick-prev {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
}
.review-slider .slick-next::before,
.review-slider .slick-prev::before {
  font-size: 38px;
  color: #ff6329;
}

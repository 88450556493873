.message-quill-editor .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.message-quill-editor .ql-snow.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.message-quill-editor .ql-editor {
  min-height: 12em;
  max-height: 12em;
}
